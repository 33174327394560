@charset "UTF-8";

.loading{
  background-color: #d3dbe6;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position:fixed;
  transform: translateY(0vh);
  z-index: 100000;

      display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

  
}
.loader .logo{
    width: 80px;
    display:block;
    margin: 0 auto;
}

.loader{
        height: 150px;
    display:block;
    margin: 0 auto;


    width: 150px;
    overflow: hidden;
}
/*
.loading .logo path{
    fill:#FFF;
}*/

.progress{
    background-color: #FFF;
    top: 148px;

    transform: translateX(-150px);
    height: 150px;
    width: 150px;
    position: absolute;
    left:0;
    z-index: 10;
}