@charset "UTF-8";


.circle {
    width: 190px;
    height: 190px;
    transform-origin: 50% 50%;
}
 
@-webkit-keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
}
 
.rotating {
    -webkit-animation: rotating 10s linear infinite;
}



