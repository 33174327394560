@charset "UTF-8";
//$rose: #FCE1DA;
$bleu: #00142B;
/*
normalise
*/
//@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT&family=Work+Sans:wght@300;600&display=swap');


//$typo1: 'Eczar', serif;
$typo2: 'Work Sans', sans-serif;
$typo1: 'Work Sans', sans-serif;
//$typo1:  'Old Standard TT', serif;

//$typo1: "ltc-bodoni-175", serif;

@import "vendors/materialize";
@import "vendors/animate";
@import "componement/base";
@import "componement/mixins";
@import "componement/flexboxgrid";
@import "componement/over_materialize";
@import "componement/menu.scss";

//@import "componement/jquery.fancybox";

@import "vendors/slick";
@import "vendors/slick-theme";

@import "componement/icons";
@import "componement/circular";
@import "componement/masonry";
@import "componement/menu";
@import "componement/loader";
@import "componement/prlxbg";
@import "componement/colorchange.scss";
@import "componement/animetext.scss";


Body {
	width: 100%;
	overflow-x: hidden;
	display: block;
	
	
}
*, *:before, *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
[data-scroll] {
  transition: opacity 1s;
}

/*
.animtext{
    
    @include transition(all .1s ease); 
}
*/
.wht a,.namedroping ul li a,
.slick-slide a{

    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow: hidden;
    position: relative;
    color: #000;

        vertical-align: baseline;
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
	text-decoration: none;

}
.namedroping ul li a:before,
.wht a:before,
.bottomleft a:before,
.slick-slide a:before{
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0%;
    height: 2px;
    width: 100%;
    background-color: #000;
    transform: translateX(-100%);
    @include transition(transform .3s ease); 
}
.bottomleft a,.bottomleft span{
  display:inline-block;
  position: relative;
  overflow: hidden;
}
.bottomleft a:before{
  background-color: #fff;
}
.wht a:hover:before,
.namedroping ul li a:before,
.slick-slide a:before,
.bottomleft a.active:before{

    transform: translateX(0%);

}
a.trait.active{
  font-weight:bold;
}
.slick-slide a:before {
	background-color: #FFF;
}
.valign-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    position: relative;


}


body,html,* body *{
    font-family: $typo2;
    font-weight: 300;
   
}
html{

            box-sizing: border-box;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
        line-height: 1;
}
/* style */
body,html, body *,.txt,.txt *{
    font-size: 1.1rem;
    letter-spacing: 1px;
    line-height: 2rem;
    font-weight: 300;
}
h1,h2,h3{
    font-family: $typo2;
    line-height: 1.4rem;
    font-weight: 600;
    font-size: 2.5rem;
    letter-spacing: 1px;
}
.h1,.h1 *{
   font-size: 5rem;
    line-height: 6.5rem;
    font-weight: 300;
    letter-spacing: .2rem;
}
.h2,.h2 *,.txt h2{
   
    font-weight: 300;
    letter-spacing: .2rem;
    font-size: 2rem;
    line-height: 2.5rem;
}
.h3,.h3 *,.txt h3{
   font-size: .8rem;
    line-height: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0rem;
}
.menu li, .menu li a{
  font-size: 2rem;
}
.wht,.wht *{
  color: #000;
}
.blck,.blck *{
  color: #fff;
}
/* !style */

.container{
    width: 90%;
    margin:0 auto;
    display: block;
    position: relative;
}
.container2{
    width: 70%;
    margin:0 auto;
    display: block;
    position: relative;
}
.cntnr{
   padding: 5%; 
}








.prlxbg{
    background-position: center 0;
    overflow: hidden;

    
}

.prlx{
  // @include transition(all .1s ease); 
}
h1{
        margin: 0;
    padding: 0;
}

*{
        transform-origin: 0 0;
}



.imgbg{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;

}

body{
     background-color: #fff;
}
.topimg{
    mix-blend-mode:multiply;
   
        pointer-events: none;
    z-index: 0;
}
.decale{
    margin-left: -10%;
}
h1.decale{
    color: #FFF;
}

.lefttotop{
    transform: rotate(-90°);
}

.grey{
    background-color: #333;
    display:block;
    height:800px;
    width: 100%;
}

.firstsection .cover{
    opacity: .4;
}
footer{
    display: block;
    background-color: #FFF;
}



#chatra__iframe-wrapper{
    border-radius: 0!important;
}
#chatra__iframe *,
.header__welcome-text *{
    text-transform: uppercase;
    font-family: $typo2!important;
    font-weight: 600;

}

.first{
    min-height: 100vh;

}
.menu{
        padding-left: 5%;
    padding-right: 5%;
}

.gg-arrow-bottom-right {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid;
    transform: scale(var(--ggs,1));
    border-right: 2px solid
}

.gg-arrow-bottom-right::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 14px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    bottom: 3px;
    right: -3px
}
#logofooter{
    width: 10vw;
    display: block;
    float: left;
    padding-right: 5vw;
    margin-bottom: 5vw;
}
.footercontent{
    display: block;
    float: left;
    width: 50vw;
}
.titre2,.titre2 .letter{
    padding-bottom: 30px;
}
.titre2:before{
    content: "↘ ";
}
footer h3{
        margin-top: -15px;
}
h3{
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
    margin-top: 0;
}
.hr{
    border-bottom: 1px solid #000;
    display:block;
    position: relative;
    width: 100%;
}
.linksrs {
	list-style: none;
    font-weight: 800;
	text-align: center;
	margin-top: 0;
}
.linksrs li {
	display: inline-block;
}
.linksrs li a {
	    display: inline-block;
    font-family: $typo2;


	text-decoration: none;
	    margin-left: 10px;
}
#logofooter:before, .logo:before {
	content: none;
}
#follower,#follower1,.follower {
	position:absolute;
	display:block;
	z-index:5;
	top: 0;
	left: 0;
	width: 70vh;
	-webkit-transform-origin: 0 0;
	margin-top: 0;
	    display: block;
    width: 1px;
    height: 1px;
}
.listhoverimage {
	display: block;
}
.namedroping li,
.listhoverimage li,
.listhoverimage li a {
	display: block;
	font-family: $typo2;
}
.emoji {
	    font-weight: 400;
    font-family: apple color emoji,segoe ui emoji,noto color emoji,android emoji,emojisymbols,emojione mozilla,twemoji mozilla,segoe ui symbol;
	font-size: inherit;
}
.blck {
	color: #FFF;
	background-color: #000;
}
section {
	padding-top: 100px;
	padding-bottom: 100px;
}
.first {
	height: 101vh;
}
.first .animtext .bigtitle .letter,
a,span {
	    font-size: inherit;
    line-height: inherit;
}
.first .animtext .bigtitle .letter:first-child {
	padding-right: 36px;
}
.first .animtext .bigtitle .letter:nth-child(2) {
	padding-right: 0px;
}
.valign-wrapper>div {
	width: 100%;
}
.first .animtext {
	text-align: center;
}

.letters {
	letter-spacing: 5px;
	    font-size: inherit;
	
}
.letters .letter {
	letter-spacing: 0;
	    font-size: inherit;
}
#bigtextaccueil {
	letter-spacing: inherit;
}



#logo {
	    position: fixed;
        top: 30px;
    left: 30px;
}
section {
		display: block;
		overflow: hidden;
}

/*.circle {
	    top: 10px;
    left: calc(50vw - 96px);
}
#logo {
    position: fixed;
    top: 69px;
    left: calc(50vw - 24px);
}*/
.zero,
.zero .row,
.zero .row .col,
.zero .row .col .slickhome,
.slick-list,
.slick-track {
	height: 100vh;
	margin: 0;
	padding: 0;
}
.zero {
	width: 100vw;
}
.slick-dots li button:before, 
.slick-next:before, 
.slick-prev:before {
	font-family: inherit;
}
.slick-next {
    right: 0;
}
.slick-prev {
    left: 0;
}

.homeleft {
	height: 100%;
}
.next:after {
	
	content: "↓";

}



.valign-wrapper .container {
    width: 90%;
}
.presta {
	max-width: 70%;
}
#presta h3,
.soustitre,
.namedroping h3 {
  
    opacity: .5;
    font-family: $typo2;
    
    padding-top: 50px;
	
	line-height: 2.5vw;
}

h2 {
	font-family: $typo1;
	max-width: 60%;
    max-width: 80%;
	
}
.zero h2 {
	padding-top: 30px;
}
.row .col {
    padding: 0;

}
.listhoverimage .soustitre {
	padding-top: 0;
}
/*
.namedroping ul {
	-webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
}*/
.namedroping ul li a {
	display: inline-block;
	
}
.namedroping ul li{
      display: inline-block;
          padding-right: 20px;
    margin-right: 20px;
}
#follower1 img {
	border: 1px solid #333;
	background-color: #FFF;
}
.namedroping ul li {

}
.namedroping ul li.hoverimage {
	color: #000;
	cursor: zoom-in;
}
.follower img {
	
	max-width: 450px;

    margin-top: -40vh;
	
}
.slick-slide h2 {


	color: #FFF;
	padding: 0;
	margin: 0;
}
.slick-slide h3,
.slick-slide a {

	color: #FFF;
	padding: 0;
	margin: 0;
}
.slick-slide {
	position: relative;
}
.encart {
	position: absolute;
	bottom: 5vw;
	left: 5vw;
}
#first2 h1 {
	position: absolute;
	top: 5vw;
	left: 5vw;
	color: #FFF;
	font-family: $typo1;

	
	    position: absolute;
    top: 5vw;
    left: 8vw;
	transform-origin: Top left;
	transform: rotate(90deg);
}
#first2 .nextslide {
	    display: block;
    position: absolute;
    right: 5vw;
    bottom: 5vw;
    color: #FFF;
	    cursor: pointer;
		
}

.decocontact {
	font-size: 28vw;
    color: #eee;
    position: absolute;
    bottom: -107px;
    left: -22px;
    mix-blend-mode: multiply;
    pointer-events: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    display: block;
    overflow: hidden;
    height: 23vw;
}
body,html {
	    line-height: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    
}



.marquee {
  height: 25px;
  width: 420px;

  overflow: hidden;
  position: relative;
      position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25vw;
    bottom: 0px;
    left: 0px;
  
    mix-blend-mode: multiply;
    pointer-events: none;
	overflow: hidden;
	height: 19vw;
}

.marquee div {
  display: block;


  position: absolute;
  overflow: hidden;
    width: 200%;
    height: 100%;
    animation: marquee 5s linear infinite;
}

.marquee span {
      float: left;
    width: 50%;
    color: #EEE;
    mix-blend-mode: multiply;
	padding-right: 30px;
    display: block;
    font-size: 24vw;
    font-weight: 600;
    opacity: .5;
	    line-height: 21vw;
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}


.fb_iframe_widget {
    display: block!important;
    position: relative!important;
    margin: 0 auto;
    width: 340px;
    margin-top: 100px;
        -webkit-transition: -webkit-box-shadow .25s;
    transition: -webkit-box-shadow .25s;
    transition: box-shadow .25s;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
   
        -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    z-index: 1;
}
section{
    overflow:visible; 
}
.progress{
    background-color: #FFF;
}

.bgvid{
        position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.bgvid:after{
 position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    content:"";
    background-color: rgba(0,0,0,.2);
}
.bgvid video{
    min-width: 100%;
    min-height:100%;

    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */

}
.play{
    background-color: red;
    width: 100px;
    height: 30px;
}
#section1 .row{
    opacity: 1;
    transition: all 200ms;
}
#section1 .row.open{
    opacity: 0;
}
/* button video*/
.video-play-button {
  position: relative;



  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ba1f24;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #fa183d;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(#fa183d, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}



.video-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.80);
  opacity: 0;
  transition: all ease 500ms;
}

.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1;
}


.video-overlay-close,.vidclose {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;

      width: 50px;
    height: 50px;
    text-align: center;
    background-color: #000;
    border-radius: 50px;
    padding-top: 6px;
    z-index:10000;
    opacity: 0;
}

.video-overlay-close.open,
.vidclose.open{
  opacity: 1;  
}

.video-overlay-close:hover,.vidclose:hover {
  color: #fa183d;
}

.video-overlay iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* width: 90%; */
  /* height: auto; */
  box-shadow: 0 0 15px rgba(0,0,0,0.75);
}

.content ul{
        display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;

    padding-left: 0;
    list-style-type: none;

    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
}

.menu ul{
        top: 19px;
}
li.socialicons,
li.socialicons a{
    padding-left: 5px;
    padding-right: 0;
}
li.socialicons a i{
    font-size: 24px;
}
footer{
    height:200px;
    width: 100%;
    display:block;
}
.namedroping h3{
    display: block;
    position:relative;
    width: 100%;
        padding-bottom: 30px;
        color: #1e4f84;
        opacity: 1;
            font-weight: 300;
}

.loader .logo {
    width: 120px;
    display: block;
    margin: 0 auto;
    padding-top: 17px;
}
.cntnr{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
        height: 100%;
    position: absolute;
    width: 100%;
}
.cntnr *{
    text-align: center;
}

.namedroping{
  opacity:0;
  display: none;
  position: relative;
  width: 100%;
  transition: all ease 500ms;
  transform: translateY(-50px);

}

.namedroping.active{
  display: block;
  transform: translateY(0px);
  opacity: 1;
}

.namedroping ul li:after{
  content: ".";
  position: absolute;
  bottom: 0;
  right:0;
  display: block;

}
footer {
    overflow: hidden;
}
a.openBlock{
  cursor:pointer;
}

.cover{
  display: block;
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background-size: cover;
  background-position: center center;
}
article{
  padding-top: 100px;

}
article .container2{
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: 0;
    -webkit-transform-origin: 0 0;
    position: relative;
    transform-origin: 0 0;
}

.row.masonry .grid-item {
    padding: 0 0 0 0px;
    margin-bottom: 30px;
    margin-top: 0;
}

.presta i {

    padding-top: 30px;
        font-size: 40px;
    color: #00d07a;
}

#presta.servicesDistrib h3{

    color: #000;

    opacity: 1;
    padding-top: 10px;
}

.chngclr.to_blck li.socialicons a i {
    color: #000;
}
.centertxt{
  text-align: center;
}
.valign-wrapper{

  height:100%;
}

#presta.servicesDistrib h2{
  padding-bottom: 50px;
}

.btn, .btn-large, .btn-small {
    text-decoration: none;
    color: #fff;
    background-color: #26a69a;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
}
.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    user-select: none;
    vertical-align: middle;
    z-index: 1;
    transition: .3s ease-out;
}

.btn-large.active, .btn-large:focus, .btn-large:hover, .btn-large:visited, .btn.active, .btn:focus, .btn:hover, .btn:visited, .btn, .btn-flat, .btn-large, .btn-small {
    color: #FFF;
    background-color: #00d07a;
    border-radius: 26px;
    box-shadow: none!important;
    font-family: "Work Sans", sans-serif;
}

#bigtextdistrib, #bigtextdistrib *,#bigtextdistrib h2{
  font-family: "Work Sans", sans-serif;
}

#article.distrib{
      content: "";
    background: linear-gradient(transparent,rgba(0,0,0,.4));
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
}

.st0{fill:#1E4F84;}
  .st1{fill:#5384B7;}
  .st2{fill:#9FBFE2;}


.page1 #section1 .cover:before{  
      content: "";
    background: linear-gradient(transparent,rgba(0,0,0,.4));
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
}
.fullheight{
  height:100vh;
  display: block;
  position: relative;
}

.logos li {
    width: 15vw;
    height: 10vw!important;
    height: auto;
    display: block;
    float: left;
    opacity: .5;
    background-size: 44% auto;
    background-position: center center;
    background-repeat: no-repeat;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    border: 1px solid #EEE;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -ms-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}
.logos li:hover {
      filter: none;
      opacity: 1;
    }
.valign-wrapper>div.container{
  width: 90%;
  display: block;
}

.bleu{
  background-color: rgba(#1e4f84,.2);
}
.center{
  display:inline-block;
  position:relative;
  text-align: center;
  margin-top: 0 auto;
}
#first1 svg{
  max-height: 600px;
  padding-top: 100px;
  padding-bottom:100px;
}

.menu li, .menu li a {
    font-size: 1.4rem;
    letter-spacing: 0;
}
#logocolor .st0{fill:#ED1E24;}
#logocolor .st1{fill:#F26922;}
#logocolor .st2{fill:#FED600;}
/* end video button */
.menu ul{
  top:26px;
}
#logo{
      top: 24px;
}
header{
  z-index:10000;
   position:fixed;
   top:0;
  left:0;
}
.menu{

}
.menu .bgmenu{
  height: 126px;
  width: 100%;
  background-color: #FFF;
  display: block;
  opacity: 0;
      position: absolute;
    top: 0;
    left: 0;
  
  

  @include transition(opacity .1s ease);
}
.menu.to_blck .bgmenu{
  opacity:1;
  border-bottom: 1px solid #000;
}
#bigtextdistrib, #bigtextdistrib *{
      font-size: 4rem;
    line-height: 4.5rem;
}
.bottomleft{
  display:block;
  position: absolute;
  bottom:30px;
  left:30px;
}

.bleu {
    background-color: #d3dbe6;
}

@media screen and(max-width: 800px) {


    
}

@media screen and(max-width: 650px) {
   .zero .row .col{
    width: 100%;
   }
   #bigtextaccueil, #bigtextaccueil * {
    font-size: 1.5em;
    line-height: 1.2em;
  }
  .row .col.s6{
    width: 100%;
  }
  .menu li a{
    font-style: 20px;
  }
  .menu ul.linemenu li{
    display: block;
    text-align: right;
  }
   .menu ul.linemenu li a{
    font-size: 20px;
   }
  
  .menu ul.linemenu li.socialicons{
    display: inline-block;
  }
    li.socialicons a{
      padding-left: 2px;
    }
  li.socialicons a i{
    font-size: 18px;
  }
  .presta {
    max-width: 100%;
  }
  .menu ul{
    top: 0
  }
  h1 *, h2 * {
    letter-spacing: 0.1rem;
  }
  .container2 {
    width: 80%;
  }
  
}


@media screen and(max-width: 650px) {
   #bigtextaccueil, #bigtextaccueil * {
    font-size: 1.2em;
    line-height: 1.2em;
  }
    #section1.valign-wrapper{
        display: block;
        background-color: #d3dbe6;

    }
    
    #article,#presta{
        background-color: #fff;
    }
    #section1 .bgvid,
    #section1 .bgvid video{
        position: relative;
        width: 100vw;
        height: auto;
        display: block;
    }
    #section1 .bgvid video{
        left: auto;
    top: auto;
    transform: none;
    }
    main{
            padding-top: 125px;
        background-color: #222;
    }
    .menu ul.linemenu li.socialicons {
    display: inline-block;
    float: right;
    padding-left: 11px;
    padding-top: 2px;
    }
    .menu li, .menu li a{
        padding-right: 0;
    }
    #first1 svg {
    max-height: 230px;
    padding-top: 10px;
    padding-bottom: 10px;
    }
    #presta{
        padding-top: 50px;
    }
    #first1{
        display: none;
    }
    .row {
    margin-bottom: 0px;
    }
    #plateformes h2{
        margin-top:0;
        padding-top: 20px;
    }
    .logos li {
    width: 50%;
    height: 150px!important;
    }
    #article h2{
        line-height: 3rem;
    }
    footer {
    height: 160px;
    }
    .marquee {
    height: 100%;
        display: none;
    }
    .marquee span{
        font-size: 200px;
    }
    
    
}

@media screen and(min-width: 1400px) {
      .menuinner {
        
        padding-left: 2.5%;
    }
}