@charset "UTF-8";

.prlxbg{
	overflow: hidden;
}
.prlxbg img{
	 min-width: 100%;
  min-height: 100%;
  position: absolute;
  transform:translate3d(0px, 0px, 0px);

  object-fit: cover;
}
.prlx{
	transform:tranlateY(0);
}