@charset "UTF-8";

h1 *,h2 *{
    font-family: $typo2;
    font-size: 24px;
    line-height: 1;

    font-weight: 400;
  font-size: 6vw;
      letter-spacing: .2rem;

      vertical-align: top;
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
	line-height: 6.7vw;
    
}

.animtext {


  display: block;
  width: 100%;
}

.animtext .letter {
  display: inline-block;



    position: relative;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;


 
        margin: 0;


}

