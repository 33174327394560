@charset "UTF-8";

@font-face {
  font-family: "alterk";
  src:url("../fonts/alterk.eot");
  src:url("../fonts/alterk.eot?#iefix") format("embedded-opentype"),
    url("../fonts/alterk.woff") format("woff"),
    url("../fonts/alterk.ttf") format("truetype"),
    url("../fonts/alterk.svg#alterk") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "alterk" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "alterk" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "\61";
}
.icon-instagram:before {
  content: "\62";
}
.icon-twitter:before {
  content: "\63";
}
.icon-facebook:before {
  content: "\64";
}
.icon-soundcloud:before {
  content: "\65";
}
.icon-letter-mail:before {
  content: "\6d";
}
.icon-hi-logo:before {
  content: "\77";
}
.icon-close:before {
  content: "\79";
}
.icon-plus2:before {
  content: "\7a";
}
.icon-social-spotify:before {
  content: "\42";
}
.icon-deezer-copie:before {
  content: "\45";
}
.icon-rocket:before {
  content: "\46";
}
.icon-magic-wand:before {
  content: "\47";
}
.icon-stats2:before {
  content: "\48";
}
.icon-bullhorn:before {
  content: "\49";
}
.icon-left:before {
  content: "\66";
}
.icon-right:before {
  content: "\6b";
}

