@charset "UTF-8";

$colorplaceholder : #EEE;



a,a:checked,a:focus {
   outline: 0;
}

*{
	outline: none;
}

html, body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: relative;
  height: auto;
  clear: both;
}

.blur{
	-webkit-filter: blur(3px);
	 -moz-filter: blur(3px);
	 -o-filter: blur(3px);
	 -ms-filter: blur(3px);
	 filter: blur(3px);
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}


.CenterH {
      text-align: center;
      padding-top: 0px;
      display: table;
      width: 100%;
      height: 100%;
}
  
.clear{
	clear: both;
}
  
.CenterH {
      text-align: center;
      padding-top: 0px;
      display: table;
      width: 100%;
      height: 100%;
}
.cover{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.cntr{
	text-align: center;
		padding: 3%;
}

/*::-webkit-scrollbar { 
    display: none; 
}*/


section{
	width: 100%;
	display: block;
	position: relative;
} 
.container{
	display: block;
	position: relative;
	margin: 0 auto;
	width: 85%;
}


/*
base
*/

body {
    display: block;
    margin: 0px;
}

 body,html{
 	background-color: #FFFFFC;
 	color: #222;
 	    font-weight: 400;
 	font-family: $typo1;
 }

  section{
	width: 100%;
	display: block;
	position: relative;
	min-height: 100px;
}



::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $colorplaceholder;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $colorplaceholder;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $colorplaceholder;
}
:-moz-placeholder { /* Firefox 18- */
  color: $colorplaceholder;
}

/*
###########################
carousel 
########################### 
*/
.carousel .carousel-item{
	width: 100%;
	display: block;
	min-height: 300px;
	text-align: center;
}
.carousel .carousel-item p{
	margin: 0 auto;
	max-width: 400px;
}




/*
###########################
pace
########################### 
*/
body{
	    
	    width: 100%;
}

body.pace-done main{
	opacity: 1;
	
}

body.pace-done main {
    opacity: 1;
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100vh;
}




.map{
	display: block;
	width: 100%;
	height: 450px;
	position: relative;
}


img{
	max-width: 100%;
}

.cd-auto-hide-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  transition: transform .5s;
}
.cd-auto-hide-header.is-hidden {
  transform: translateY(-100%);
}

.cd-secondary-nav.fixed {
  position: fixed;
  top: 60px;
}
.cd-secondary-nav.slide-up {
  transform: translateY(-60px);
}

