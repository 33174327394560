@charset "UTF-8";

.chngclr *
{
    color: #FFF;
    //fill: #FFF;
    @include transition(all .3s ease); 
}

.chngclr.to_wht li,
.chngclr.to_wht li a,
.chngclr.to_wht path,
.chngclr.to_wht span,
.chngclr.to_wht *
{
    color: #FFF;
    //fill: #FFF;
    @include transition(all .3s ease); 
}

.chngclr.to_blck li,
.chngclr.to_blck li a,
.chngclr.to_blck path,
.chngclr.to_blck span,
.chngclr.to_blck *{
    color: #000;
    //fill: #000;
    @include transition(all .3s ease); 
}