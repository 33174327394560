@charset "UTF-8";


.menu{
       position: fixed;
    top: 0vw;
    left: 0vw;
    width: 100%;
    display: block;
    z-index: 10000;

}
.menu ul{
    position: absolute;
    top: 0vw;
    right: 0vw;
    display: block;
}

.menu li,.menu li a{
    display: inline-block;
    color: #fff;
    text-decoration: none;
    padding-right: 1vw;
    font-style: normal;
    line-height: 1;
}



.menuoverlay{
    width: 100vw;
    height:100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FFF;
    z-index: 9999;
}
#navigation{
    opacity: 0;
    pointer-events: none;
}

#navigation.open{
    opacity: 1;
    pointer-events: auto;
}


.menu{
       position: fixed;
    top: 0vw;
    left: 0vw;
    width: 100%;
    display: block;
    z-index: 10000;

}
.menu ul{
    position: absolute;
    top: 0vw;
    right: 0vw;
    display: block;
}
#logo{
    display: block;
    position: relative;
}
#logo svg{
    width: 80px;
}
#logo span{
    font-family: $typo1;

    position: absolute;
    top:24px;
    left:74px;
    font-size:2.5rem;
    font-weight: 400;
    width: 300px;
}
